<template>
  <BorderWrapper>
    <div class="contianer">
      <controlActionsButtons
        :ActionsList="[
          { name: 'סוכנים', path: '/' },
          { name: 'אזורי חלוקה', path: '/' },
        ]"
        :selectedAction="selectedAction"
        @selectAction="selectAction"
        mode="controlPanel"
      />
      <SideBarList
        v-if="getUserRole?.agentapp && selectedAction === 'סוכנים'"
        :Categories="agentsList"
        queryKey="agentId"
        defaultValue="סוכן"
        mode="customers"
      />
      <div v-if="controlPanelList.length > 0" class="grid">
        <DistributionAreas
          v-for="controlPanel in controlPanelList"
          :key="controlPanel"
          :distributionArea="controlPanel"
          :isAgents="isAgents"
        />
      </div>
    </div>
  </BorderWrapper>
</template>

<script>
import { mapGetters } from "vuex";
import BorderWrapper from "../UI/BorderWrapper.vue";
import controlActionsButtons from "../helpers/controlActionsButtons.vue";
import DistributionAreas from "../helpers/DistributionAreas.vue";
import SideBarList from "../menus/SideBarList.vue";

export default {
  components: {
    BorderWrapper,
    controlActionsButtons,
    DistributionAreas,
    SideBarList,
  },
  computed: {
    ...mapGetters({
      Parts: "Parts/getParts",
      getCustname: "AuthModule/getCustname",
      getUserLang: "AuthModule/getUserLang",
      isMobile: "isMobile",
      isLaptop: "isLaptop",
      isDesktop: "isDesktop",
      getAgentsList: "ControlPanel/getAgentsList",
      getDistLinesList: "ControlPanel/getDistLinesList",
      getUserRole: "AuthModule/getUserRole",
    }),
    isRestricModelOpen() {
      return !this.getUserRole?.agentapp;
    },
    agentsList() {
      return this.getAgentsList.map((agent) => {
        return {
          id: agent.agent,
          name: agent.agentname,
        };
      });
    },
    controlPanelList() {
      let controlPanelList = this.isAgents
        ? this.getAgentsList
        : this.getDistLinesList;
      if (this.$route.query.agentId && this.isAgents) {
        controlPanelList = controlPanelList.filter(
          (agent) => agent.agent == this.$route.query.agentId
        );
      }
      return controlPanelList;
    },
  },
  async mounted() {
    this.intervalId = setInterval(async () => {
      await this.$store.dispatch("ControlPanel/agentsList");
      await this.$store.dispatch("ControlPanel/distLinesList");
      if (!this.getUserRole?.agentapp)
        await this.$store.dispatch("Customers/getCustomers");
      if (this.isDesktop || this.isLaptop) {
        await this.$store.dispatch("AgentRequests/agentRequestsIntervalUpdate");
      }
    }, 5000);
  },
  data() {
    return {
      isModalOpen: false,
      selectedAction: "סוכנים",
      isAgents: true,
      intervalId: null,
    };
  },
  methods: {
    selectAction(action) {
      console.log(action.name);
      this.selectedAction = action.name;
      if (action.name === "סוכנים") {
        this.isAgents = true;
      } else {
        this.isAgents = false;
      }
    },
  },
  unmounted() {
    this.$store.commit("setIsShowDesktopHome", false);
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped lang="scss">
.contianer {
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (max-width: 390px) {
  .modal-container {
    width: 99% !important;
    padding: 5px !important;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(390px, 1fr));
  justify-items: center;
  gap: 32px;
}

@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 430px) {
  .grid {
    grid-template-columns: unset;
  }
}

//old
.search {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 15px;
}
* {
  text-align: center;
}
h3 {
  font-size: 20px;
  margin: 0;
  margin-top: 100px;
  margin-bottom: 10px;
  font-weight: 600;
}
h2 {
  font-size: 30px;
  margin: 0;
  color: $primaryColor;
}
.col {
  float: unset;
}
img {
  width: auto;
  height: 340px;
  margin: 0 auto !important;
  display: block;
}
</style>
