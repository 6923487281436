<template>
  <v-fade-transition>
    <BaseSideBar
      v-if="!isSingelOrder"
      :class="[isOpenMobileSideBar ? 'pa-4' : '']"
      class="d-flex ga-6 flex-column"
    >
      <BaseSearch
        @update-search-word="updateUserSearchInput"
        :searchword="userSearchInput"
        :placeholder="'חיפוש לפי הזמנה'"
        isSearchHeader
        style="max-width: 500px;"
        mode="orders"
      />
      <div class="">
        <!-- list of Statuses -->
        <SideBarTitle
          key="title"
          mode="orders"
          :isSideBarTitleShown="!needToCloseSideBar"
        />
        <FiltersDisplay>
          <ControlActionsButtons
            class="mt-3 mb-3"
            :ActionsList="actionList"
            :selectedAction="selectedAction"
            @selectAction="selectAction"
            mode="orders"
          />
          <v-divider length="268px" class="ma-5 mr-0"></v-divider>

          <datePickerButton
            mode="order"
            class="mb-4"
            :header="'ת.הזמנה'"
            :width="'150px'"
            @openModal="openDatePicker('orderDate')"
            :active="orderDate.trim().length > 0"
          >
            <div>
              <v-text-field
                class="flex-1-0 date-picker pr-3"
                hide-details="auto"
                :append-inner-icon="
                  orderDate ? 'mdi-close' : 'mdi-calendar-range'
                "
                :model-value="formattedDate(orderDate, 'ת.הזמנה')"
                variant="plain"
                style="pointer-events: none"
              ></v-text-field>
            </div>
          </datePickerButton>

          <div class="d-flex flex-column ga-4">
            <SideBarList
              :Categories="getFilters?.distlines"
              queryKey="distLine"
              defaultValue="קו חלוקה"
              mode="orders"
            />

            <SideBarList
              v-if="getUserRole?.agentapp"
              :Categories="getFilters?.agents"
              queryKey="agentId"
              defaultValue="סוכן"
              mode="orders"
            />

            <SideBarList
              :Categories="getFilters?.customers"
              queryKey="custId"
              defaultValue="לקוח"
              mode="orders"
            />

            <SideBarList
              :Categories="getFilters?.statuses"
              queryKey="status"
              defaultValue="סטטוס"
              mode="orders"
            />
          </div>
        </FiltersDisplay>
      </div>
      <datePickerModal
        @update:modelValue="updateDate"
        @trigger-date-picker-update="updateDate"
        :openModal="openModalDatePicker"
      />
    </BaseSideBar>
  </v-fade-transition>
</template>

<script>
import BaseSideBar from "./BaseSideBar.vue";
import { mapGetters } from "vuex";
import SideBarTitle from "./SideBarTitle.vue";
import SideBarList from "./SideBarList.vue";
import ControlActionsButtons from "../helpers/controlActionsButtons.vue";
import datePickerButton from "@/components/UI/datePickerButton.vue";
import datePickerModal from "@/components/UI/modals/datePickerModal.vue";
import { dateToDisplay } from "@/dates";
import FiltersDisplay from "./FiltersDisplay.vue";
import BaseSearch from "./BaseSearch.vue";
import moment from "moment";

export default {
  components: {
    BaseSideBar,
    SideBarTitle,
    SideBarList,
    ControlActionsButtons,
    datePickerButton,
    datePickerModal,
    FiltersDisplay,
    BaseSearch,
  },
  data() {
    return {
      selectedAction: "כל ההזמנות",
      orderDate: "",
      openModalDatePicker: false,
      userSearchInput: "",
      actionList: [
        { name: "כל ההזמנות", path: "allorders" },
        { name: "הזמנות למחר", path: "tommorroworders" },
      ],
    };
  },
  mounted() {
    if (this.$route.query.orderDate) {
      this.orderDate = moment(this.$route.query.orderDate)
        .toDate()
        .toISOString();
    }
    this.applyPaginationFilter();
    const path = this.getPathParam();
    const currentAction = this.actionList.find(
      (action) => action.path === path
    );
    if (currentAction) {
      this.selectAction(currentAction);
    }
  },
  unmounted() {
    this.$store.commit("setIsOpenMobileSideBar", false);
  },

  watch: {
    "$route.query"() {
      this.applyPaginationFilter();
    },
  },
  methods: {
    getPathParam() {
      let path = this.$route.path;
      if (path.includes("/")) {
        path = path.length > 2 ? path.split("/")[2] : path;
      }
      return path;
    },
    async applyPaginationFilter() {
      const path = this.getPathParam();
      await this.$store.dispatch("Orders/getOrdersPagination", {
        ...this.$route.query,
        [path]: path,
      });
    },
    updateUserSearchInput(value) {
      this.userSearchInput = value;
    },
    updateDate(date) {
      this.openModalDatePicker = false;
      let query = { ...this.$route.query };

      if (date) {
        this.orderDate = moment(date).toDate().toISOString();
        query.orderDate = this.orderDate;
      } else {
        this.orderDate = "";
        delete query.orderDate;
      }

      this.$router.push({ path: `${this.$route.path}`, query: query });
    },
    openDatePicker() {
      if (!this.orderDate) {
        this.openModalDatePicker = true;
      } else {
        this.updateDate(null);
      }
    },
    formattedDate(date, text) {
      if (!date) return text;
      return dateToDisplay(date);
    },
    selectAction(action) {
      this.selectedAction = action.name;
      console.log(action.path);
      const query = {
        ...this.$route.query,
      };
      this.$router.push({ path: action.path, query: query });
    },
  },
  computed: {
    isSingelOrder() {
      return this.$route.name === "Order";
    },
    isLangHebrew() {
      return this.getUserLang == "he" ? true : false;
    },
    needToCloseSideBar() {
      if (
        (this.$store.getters.isMobile || this.$store.getters.isTablet) &&
        !this.$store.state.openMenu
      ) {
        // this.$store.commit("toggleMenu");
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      getOrdersStatuses: "Orders/getOrdersStatuses",
      getOrdersAgents: "Orders/getOrdersAgents",
      getOrdersDistLines: "Orders/getOrdersDistLines",
      getOrdersCustomers: "Orders/getOrdersCustomers",
      getFilters: "ControlPanel/getFilters",
      isTablet: "isTablet",
      isLaptop: "isLaptop",
      isDesktop: "isDesktop",
      isOpenMobileSideBar: "isOpenMobileSideBar",
      getUserRole: "AuthModule/getUserRole",
    }),
    getParamStatus() {
      return this.$route.params.status;
    },
  },
};
</script>

<style scoped lang="scss">
.title-section {
  padding: 0px 20px 0px 20px;
}
.orders-icon {
  color: $ordersMainColor;
  background-color: #44a5e233;
  border-radius: 50%;
  padding: 17px;
}
@media (max-width: 601px) {
  .side-bar-container {
    // padding: unset;
  }
}
.menu-open-mobile-enter-from,
.menu-open-mobile-leave-to {
  max-height: 0px;
}
.menu-open-mobile-enter-active,
.menu-open-mobile-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
}
.menu-open-mobile-enter-to,
.menu-open-mobile-leave-from {
  max-height: 1000px;
}
h2 {
  font-size: 30px;
  font-weight: 700;
  color: $ordersMainColor;
  line-height: 35.55px;
  text-align: right;
}

.material-icons {
  font-size: unset;
}
.collection-item {
  text-decoration: none;
  span {
    font-weight: 400;
    padding: 10px;
  }
}
.menu-collection {
  .v-list-item-title {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
    color: $statusListColor;
  }
}
</style>
