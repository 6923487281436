<template>
  <BaseSideBar :class="[isOpenMobileSideBar ? 'pa-3' : '']">
    <SideBarTitle mode="customers" :isSideBarTitleShown="!needToCloseSideBar" />
    <FiltersDisplay>
      <div key="child">
        <ControlActionsButtons
          class="mt-3 mb-3"
          :ActionsList="actionList"
          :selectedAction="selectedAction"
          @selectAction="selectAction"
          mode="customers"
        />

        <v-divider length="268px" class="ma-5 mr-0"></v-divider>

        <div class="d-flex ga-3 flex-column">
          <SideBarList
            :Categories="getCustomersDistLines"
            queryKey="distLine"
            defaultValue="קו חלוקה"
            mode="customers"
          />

          <SideBarList
            v-if="getUserRole?.agentapp"
            :Categories="getCustomersAgents"
            queryKey="agentId"
            defaultValue="סוכן"
            mode="customers"
          />

          <SideBarList
            :Categories="getUniqueCustomer"
            queryKey="custId"
            defaultValue="לקוח"
            mode="customers"
          />

          <SideBarList
            :Categories="getCustomerStatus"
            queryKey="status"
            defaultValue="סטטוס"
            mode="customers"
          />
        </div>
      </div>
    </FiltersDisplay>
  </BaseSideBar>
</template>

<script>
import BaseSideBar from "./BaseSideBar.vue";
import { mapGetters } from "vuex";
import SideBarTitle from "./SideBarTitle.vue";
import SideBarList from "./SideBarList.vue";
import ControlActionsButtons from "../helpers/controlActionsButtons.vue";
import FiltersDisplay from "./FiltersDisplay.vue";

export default {
  components: {
    BaseSideBar,
    SideBarTitle,
    SideBarList,
    ControlActionsButtons,
    FiltersDisplay,
  },
  data() {
    return {
      selectedAction: "כל הלקוחות",
      actionList: [
        { name: "כל הלקוחות", path: "/customers/allcustomers" },
        { name: "לקוחות באפליקציה", path: "/customers/appcustomers" },
      ],
    };
  },
  unmounted() {
    this.$store.commit("setIsOpenMobileSideBar", false);
  },
  mounted() {
    const path = this.getPathParam();
    const currentAction = this.actionList.find(
      (action) => action.path.includes(path)
    );
    if (currentAction) {
      this.selectAction(currentAction);
    }
  },
  methods: {
    getPathParam() {
      let path = this.$route.path;
      if (path.includes("/")) {
        path = path.length > 2 ? path.split("/")[2] : path;
      }
      return path;
    },
    selectAction(action) {
      this.selectedAction = action.name;
      const query = {
        ...this.$route.query,
      };
      this.$router.push({ path: action.path, query: query });
    },
  },
  computed: {
    needToCloseSideBar() {
      if (
        (this.$store.getters.isMobile || this.$store.getters.isTablet) &&
        !this.$store.state.openMenu
      ) {
        // this.$store.commit("toggleMenu");
        return true;
      } else {
        return false;
      }
    },
    ...mapGetters({
      getCustomersDistLines: "Customers/getCustomersDistLines",
      getCustomersAgents: "Customers/getCustomersAgents",
      getUniqueCustomer: "Customers/getUniqueCustomer",
      getCustomerStatus: "Customers/getCustomerStatus",
      isOpenMobileSideBar: "isOpenMobileSideBar",
      getUserRole: "AuthModule/getUserRole",
    }),
  },
};
</script>

<style scoped lang="scss">
.sub-list-title:before {
  content: "| ";
  color: $customerMainColor;
  width: 5px;
  height: 16px;
  background: $customerMainColor;
  border-radius: 50px;
  margin-left: 10px;
}

.sub-list-title {
  padding-right: 15px;
  margin-bottom: 15px;
  font-size: $mediumTextSize;
  font-weight: 700;
  line-height: 21.33px;
  text-align: right;
}
.title-section {
  padding: 0px 20px 0px 20px;
}
.orders-icon {
  color: $ordersMainColor;
  background-color: #44a5e233;
  border-radius: 50%;
  padding: 17px;
}
@media (max-width: 601px) {
  .side-bar-container {
    padding: unset;
  }
}
.menu-open-mobile-enter-from,
.menu-open-mobile-leave-to {
  max-height: 0px;
}
.menu-open-mobile-enter-active,
.menu-open-mobile-leave-active {
  transition: all 0.2s ease;
  overflow: hidden;
}
.menu-open-mobile-enter-to,
.menu-open-mobile-leave-from {
  max-height: 1000px;
}
h2 {
  font-size: 30px;
  font-weight: 700;
  color: $ordersMainColor;
  line-height: 35.55px;
  text-align: right;
}

.material-icons {
  font-size: unset;
}
.collection-item {
  text-decoration: none;
  span {
    font-weight: 400;
    padding: 10px;
  }
}
.menu-collection {
  .v-list-item-title {
    font-size: $mediumTextSize;
    font-weight: 400;
    line-height: 21.33px;
    text-align: right;
    color: $statusListColor;
  }
}
</style>
