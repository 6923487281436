<template>
  <div
    class="table-actions d-flex ga-5"
    :class="{ isBtnExpand: isBtnExpand, [mode]: true }"
    :ref="id"
  >
    <v-btn
      @click="expandBtn"
      flat
      border
      class="info-menu"
      :class="{ 'pa-0': isBtnExpand }"
      :prepend-icon="prependIconBtn"
    >
      <span>{{ $t(`generalTable.columns.actions`) }}</span>
    </v-btn>
    <div v-if="isBtnExpand" class="isBtnExpand">
      <div class="operations">
        <v-icon
          @click="$emit('close-expand')"
          size="24"
          class="close-icon position-absolute"
          :color="'white'"
          :icon="'mdi-close'"
        ></v-icon>
        <v-list class="bg-transparent" density="compact">
          <div
            class="d-flex flex-column justify-center"
            v-for="(item, i) in items"
            :key="i"
          >
            <v-list-item
              @click="openOption(item.variableName)"
              :value="item"
              :title="item.text"
            >
              <template v-slot:prepend>
                <v-icon color="white" size="25" :icon="item.icon"></v-icon>
              </template>
            </v-list-item>
            <v-divider
              v-if="i < items.length - 1"
              class="mr-3"
              length="214"
              color="white"
            ></v-divider>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex/dist/vuex.cjs.js";
import { newOrderUrl } from "../../config";

// import ModalContainer from "../UI/modals/modal-container.vue";
export default {
  emits: ["expand-btn", "close-expand"],
  props: {
    backgroundColor: {
      type: String,
    },
    agnetInfo: {
      type: Object,
      required: true,
    },
    customerInfo: {
      type: Object,
      required: true,
    },
    cust: {
      type: Number,
      required: false,
    },
    isBtnExpand: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
    },
    status: {
      type: Object,
    },
    mode: {
      type: String,
      required: true,
    },
    isCustCanCreateAnOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [
        {
          text: "הזמנה חדשה",
          variableName: "newOrder",
          icon: "mdi mdi-text-box-plus-outline",
        },
        {
          text: "הודעה למשרד",
          variableName: "officeMessage",
          icon: "mdi mdi-email-fast-outline",
        },
        {
          text: "התקשר ללקוח",
          variableName: "phone",
          icon: "mdi mdi-phone-outline",
        },
        {
          text: "הודעת Whatsapp ללקוח",
          variableName: "whatsapp",
          icon: "mdi mdi-whatsapp",
        },
        // {
        //   text: 'דוא"ל ללקוח',
        //   variableName: "email",
        //   icon: "mdi mdi-email-arrow-left-outline",
        // },
      ],
    };
  },
  mounted() {
    if (this.mode === "order") {
      this.items[0].text = "צפייה בהזמנה";
      this.items[0].variableName = "viewOrder";
      this.items[0].icon = "mdi mdi-eye-outline";
      this.items.splice(1, 1);
      if (this.status?.editable) {
        const editOrder = {
          text: "עריכת הזמנה",
          variableName: "editOrder",
          icon: "mdi mdi-pencil-outline",
        };
        this.items.splice(1, 0, editOrder);
      }
    }

    if (this.mode !== "order" && !this.isCustCanCreateAnOrder) {
      this.items.splice(0, 1);
    }

    if (this.customerInfo.phoneNumber == null) {
      this.items = this.items.filter(
        (item) =>
          item.variableName !== "whatsapp" && item.variableName !== "phone"
      );
    }

    if (this.customerInfo.email == null) {
      this.items = this.items.filter(
        (item) => item.variableName !== "officeMessage"
      );
    }
  },
  computed: {
    ...mapGetters({
      getUserPhone: "AuthModule/getUserPhone",
    }),
    prependIconBtn() {
      if (!this.isBtnExpand) return "mdi-dots-horizontal";
      return "";
    },
  },
  methods: {
    expandBtn() {
      this.$emit("expand-btn");
      this.$nextTick(() => {
        var offsetHeight =
          this.$refs[this.id].querySelector(".operations").offsetHeight;
        this.$emit("setHeight", offsetHeight);
      });
    },
    openOption(variableName) {
      switch (variableName) {
        case "newOrder":
          this.openNewOrder();
          break;
        case "editOrder":
          this.editOrder();
          break;
        case "viewOrder":
          this.viewOrder();
          break;
        case "officeMessage":
          this.openEmail(this.customerInfo?.email);
          break;
        case "phone":
          this.openPhone(this.customerInfo?.phoneNumber);
          break;
        case "whatsapp":
          this.openWhatsapp(this.customerInfo?.phoneNumber);
          break;
        case "email":
          this.openEmail(this.customerInfo?.email);
      }
    },
    openEmail() {
      window.open(`mailto:${this.customerInfo?.email}`);
    },
    openWhatsapp() {
      window.open(`https://wa.me/${this.customerInfo?.phoneNumber}`);
    },
    openPhone() {
      window.open(`tel:${this.customerInfo?.phoneNumber}`);
    },
    async openNewOrder(orderId = null) {
      const response = await this.$store.dispatch("Orders/newOrder", {
        cust: this.agnetInfo,
      });
      if (response) {
        const passkey = response.passkey;
        const url = newOrderUrl(
          passkey,
          this.getUserPhone,
          this.cust,
          this.agnetInfo.agent,
          orderId
        );
        window.open(url, "_blank");
      }
    },
    viewOrder() {
      this.$router.push({ path: `/Order/${this.id}` });
    },
    editOrder() {
      this.openNewOrder(this.id);
    },
  },
};
</script>

<style lang="scss">
.isBtnExpand {
  position: relative;
}
.operations {
  position: absolute;
  z-index: 9999;
  top: -5px;
  left: 10px;
  width: 300px;
  // background: v-bind(backgroundColor);
  background: $buttonColor;
  color: white;
  border-radius: 20px;
}
.close-icon {
  z-index: 99;
}
.table-actions.isBtnExpand {
  z-index: 0;
  // min-width: 260px;
}
.table-actions .v-icon {
  color: #434343;
  font-size: 25px;
}

.table-actions .v-btn {
  border-radius: 20px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.1px;
  height: 40px !important;
  width: 100% !important;
}

.table-actions.customer .v-btn {
  border-color: $customerMainColor;
}

.table-actions.order .v-btn {
  border-color: $ordersMainColor;
}

.table-actions .v-btn {
  color: white !important;
}

.table-actions .info-menu .v-icon {
  margin-top: 1px;
  font-size: 35px;
  opacity: 1;
}

.table-actions.customer .info-menu .v-icon {
  color: $customerMainColor;
}

.table-actions.order .info-menu .v-icon {
  color: $ordersMainColor;
}

.table-actions.isBtnExpand .info-menu .v-icon {
  color: white;
  opacity: 1;
}

.table-actions.isBtnExpand .v-btn {
  height: 100% !important;
  border: none;
}

.table-actions.isBtnExpand.customer .v-btn {
  background: $customerMainColor;
}

.table-actions.isBtnExpand.order .v-btn {
  background: $ordersMainColor;
}

.table-actions.customer .v-btn .v-btn__content {
  color: $customerMainColor;
}

.table-actions.order .v-btn .v-btn__content {
  color: $ordersMainColor;
}

.table-actions.isBtnExpand .v-btn .v-btn__content {
  color: white;
}

.v-list-item__prepend {
  display: block !important;
  margin-left: 5px;
}

.v-list-item-title {
  width: fit-content;
  font-size: $mediumTextSize !important;
}
</style>
